.q10__post__section {
    padding-block: 0.5rem;
    &__block {
        margin-bottom: 2.5rem;
        &__title {
            // margin-bottom: 1rem;
            // font-size: 20px;
            font-size: 14px;
            color: #223972;
            // font-weight: 700;
            font-weight: 400;
            display: flex;
            flex-direction: row;
            .title_question{
                // margin-left: 0.5rem;
            }
 
        }
        &__subtitle {
            margin-bottom: 1rem;
        }
        &__paragraph {
            line-height: 1.5;
            font-size: 14px;
            max-height: 0;
            transition: max-height 0.15s ease-out;
            overflow: hidden;
            background: #d5d5d5;

        }
        .orizontal_line
        {
            border-top: 1px solid #efefef;
            padding: 1rem 0;
        }
        
    }
    .orizontal_line
    {
        border-bottom: 1px solid #efefef;
        padding: 0.5rem 0;
    }
    .hide {
        display: none;

    }
 
    .show{
        display:block;
    }
}

.blog_post{
    display: grid;
    place-content: center;
    width: 100%;
    position: relative;
    margin-top: 1rem;

    gap: 2rem;
    padding: 2rem;
    &_box{
        width: 100%;
    }
    @media (min-width: 768px) {
        flex-direction: row;
        padding: 2rem 10%;
    }
}