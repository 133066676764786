.q10__footer {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0rem;
    gap: 1.5rem;
    box-shadow: 0px 0 10px rgb(0 0 0 / 80%);
    bottom: 0;
    position: absolute;
    display:none;
    @media (min-width: 768px) {
        padding: 1.5rem 0.5rem;
        flex-direction: row;
        justify-content: space-between;
        gap: 0rem;

        background-color: var(--primary_color);
    }

    .link {
        color: #040505;
    }
    &__nav {
        display: flex;
        gap: 1.5rem;
        a {
            text-decoration: none;
            font-weight: bold;
        }
    }
    &__visit {
        color: #040505;
        text-align: center;
    }
}
