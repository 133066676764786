.scrolltop {
    position: fixed;
    width: 35px;
    height: 35px;
    right: 1rem;
    bottom: -20%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
    background: rgba(34,57,114,1);
    z-index: 2;
    transition: 0.4s;
    visibility: hidden;
    border-radius: 50%;
    &:hover {
        background-color:#6f95f7
    }
    .arrow {
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
    }

    .up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }
}

.show-scroll {
    visibility: visible;
    bottom: 4rem;
    place-content: center;
}
