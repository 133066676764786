.gallery-block {
    display: grid;
    place-content: center;
    padding: 0;

    padding-bottom: 3rem;
    &-title {
        text-align: center;
        font-size: 36px;
        margin: 1rem 0;
        color: #162015;
        @media (min-width: 768px) {
            //    margin: 0 0 6rem 0;
        }
    }
    &-subtitle {
        text-align: center;
        margin: 1rem 0;
        color: #162015;
        font-size: 20px;
        @media (min-width: 768px) {
            //    margin: 0 0 6rem 0;
        }
    }
    &-items {
        display: grid;
        gap: 0.5rem;
        .img-box {
            text-align: center;
            border-radius: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem;
            cursor: pointer;
            // @media (min-width: 768px) {
            //     width: 320px;
            //     height: 320px;
            // }
            width: 100%;
            height: 100%;
            object-fit: contain;
            

        }
    }
}

.popup{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 9999;

    &-content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        width: 100%;
        height: auto;
        @media (min-width: 768px) {
            width: auto;
            height: auto;
        }
    }
    &-image{
        max-width: 90%;
        max-height: 90%;
    }
    &-close{
        font-size : 18px;
        margin-top: 10px;
        margin-left: 10px;
        font-weight: bold;
        cursor: pointer;
        top: 0;
        left: 0;
        text-transform: uppercase;

    }
}
  
  img {
    max-width: 100%;
    display: block;
  }
  
  .content {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid;
  }
  
  .content > img {
    grid-row: 1 / -1;
    grid-column: 1;
    cursor:pointer;
  }
  
  .content a {
    color: black;
    text-decoration: none;
  }
  
  .masonry {
    column-gap: 10px;
  }