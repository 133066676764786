.q10__content__block {
    width: 100%;
    position: relative;
    //padding-bottom: 55px;
    .q10__content__block__content {
        color: var(--text_color);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 0 1rem;
        @media (min-width: 768px) {
            flex-direction: row;
        }
        &__item {
            flex: 1;
            margin-bottom: 2rem;
            text-align: justify;
            @media (min-width: 768px) {
                margin-bottom: 0rem;
            }
            & > * {
                margin-bottom: 1.5rem;
            }
            &__steps {
                display: flex;
                align-items: center;
                .step {
                    font-size: 22px;
                }
                .step_no {
                    font-size: 16px;
                    background-color: var(--goToBtnArrow_color);
                    color: var(--goToBtn_color);
                    width: 25px;
                    height: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 0.5rem;
                }
            }
            &__images {
                .image {
                    display: block;
                    height: auto;
                    width: 100%;
                }
            }
        }
    }
    &__content {
        // padding: 2rem 10%;
        color: white;
        position: relative;
        &__form {
            margin: 0 auto;
            &__group {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 1.5rem;
                text-align: center;
                &__title {
                    text-align: center;
                    font-size: 36px;
                    margin-bottom: 0.6rem;
                    font-weight: 400;
                }
                &__subtitle {
                    text-align: center;
                    font-size: 18px;
                    margin-bottom: 1rem;
                    font-weight: 400;
                }
                &__under_button {
                    text-align: center;
                    font-size: 18px;
                    margin-top: 1rem;
                    font-weight: 400;
                }
                &__div {
                    padding-top: 1rem;
                    &__button {
                        background-color: #f37a31;
                        color: #fff;
                        font-family: "Alata", sans-serif;
                        font-weight: 400;
                        border-radius: 0;
                        font-size: 20px;
                        width: 200px;
                        padding: 10px;
                        display: block;
                        margin: auto;
                        text-decoration: none;
                        border: none;
                    }
                }
                &__input {
                    border-bottom: solid 2px #cbcbcb;
                    border-radius: 0;
                    background: transparent;
                    font-family: "Roboto", sans-serif;
                    font-size: 20px;
                    color: #cbcbcb;
                    font-weight: normal;
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    padding: 1px 15px;
                    text-align: center;
                    width: 300px;
                    @media (min-width: 768px) {
                        width: 500px;
                    }
                }
                &__error_message {
                    color: red;
                }
            }
        }
        &__modal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.85);
            display: flex;
            justify-content: center;
            z-index: -1;
            opacity: 0;
            transition: all 250ms ease-in-out;
            &.active {
                z-index: 10;
                opacity: 1;
            }
            &__form {
                position: relative;
                background-color: var(--header_nav_color);
                color: var(--goToBtnArrow_color);
                margin: auto;
                padding: 1.5rem 2.5rem;
                border-radius: 4px;
                width: 95%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: transform 250ms ease-in-out;
                transform: scale(0);
                &.active {
                    transform: scale(1);
                }
                @media (min-width: 768px) {
                    width: 65%;
                    max-width: 700px;
                }
                & > * {
                    margin-bottom: 1.5rem;
                    text-align: center;
                }
                &__close {
                    position: absolute;
                    top: 1%;
                    right: 4%;
                    color: grey;
                    font-size: 35px;
                    cursor: pointer;
                    line-height: 1;
                }
                &__phone {
                    margin-inline: auto;
                    max-width: 285px;
                    border: 2px solid #e46617;
                    line-height: 1.5;
                    padding: 0.5rem 2rem;
                    font-size: 16px;
                    font-family: "Roboto Condensed", sans-serif;
                    border-radius: 4px;
                    outline: none;
                }
                &__prefix {
                    margin-inline: auto;
                    max-width: 285px;
                    border: 2px solid #e46617;
                    line-height: 1.5;
                    padding: 0.5rem 0.5rem;
                    font-size: 16px;
                    font-family: "Roboto Condensed", sans-serif;
                    border-radius: 4px;
                    outline: none;
                    margin: 5px 5px 5px 5px;
                }
                &__agreement {
                    position: relative;
                    display: flex;
                    cursor: pointer;
                    text-align: center;
                    margin-inline: calc(1.5rem + 18px);
                    font-family: "Roboto Condensed", sans-serif;
                    user-select: none;
                    &__checkbox {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                        &:checked {
                            ~ {
                                .q10__content__block__content__modal__form__agreement__checkmark {
                                    background-color: #fff;
                                    &:after {
                                        display: flex;
                                    }
                                }
                            }
                        }
                    }
                    &:hover {
                        .q10__content__block__content__modal__form__agreement__checkbox {
                            ~ {
                                .q10__content__block__content__modal__form__agreement__checkmark {
                                    background-color: #fff;
                                }
                            }
                        }
                    }
                    &__checkmark {
                        position: absolute;
                        top: 2px;
                        left: -1.5rem;
                        height: 18px;
                        width: 18px;
                        background-color: #fff;
                        border: 1px solid grey;
                        &:after {
                            content: "X";
                            position: absolute;
                            display: none;
                            justify-content: center;
                            align-items: center;
                            color: #000000;
                            font-weight: 900;
                            font-size: 13px;
                            font-family: "Roboto", sans-serif;
                            width: 100%;
                            height: 100%;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                    &__text {
                        font-size: 14px;
                        .data-security {
                            color: var(--btn_color);
                            text-decoration: none;
                        }
                    }
                }
                &__submit {
                    font-family: "Roboto", sans-serif;
                    outline: none;
                    border: 2px solid #e46617;
                    padding: 0.5rem 1rem;
                    font-weight: bold;
                    cursor: pointer;
                    background-color: transparent;
                    line-height: 1.5;
                    border-radius: 4px;
                    font-size: 16px;
                    color: var(--goToBtnArrow_color);
                }
            }
        }
    }
}

.q10__content__dynamic_blocks {
    //padding-block: 1rem;
    // padding-inline: 5%;
    // background-color: black;
    min-height: auto !important;
    @media (min-width: 768px) {
        // padding-inline: 15%;
    }
    &__block {
        margin-bottom: 2.5rem;
        text-align: center;
        position: relative;
        font-family: "Alata", sans-serif;
        &__title {
            font-weight: 700;
            font-size: 34px;
            line-height: 45px;
            text-transform: none;
            letter-spacing: -2.3px;
            color: #223972;
        }
        &__subtitle {
            margin-bottom: 1rem;
            color: white;
            font-size: 20px;
            font-weight: 500;
        }
        &__paragraph {
            line-height: 1.5;
            max-height: 0;
            overflow: hidden;
            transition: max-height 1s ease-out;
            transition: 2s ease-in-out;
        }
        &__images {
            .image {
                display: block;
                height: auto;
                width: 100%;
            }
        }
    }
}

.q10__content__dynamic {
    // padding: 1rem;
    @media (min-width: 768px) {
        flex-direction: row;

    }
    &__title {
        font-weight: 700;
        font-size: 34px;
        line-height: 45px;
        text-transform: none;
        letter-spacing: -2.3px;
        color: #223972;
    
    &:after {
        border-bottom: 1px solid #e4e4e4;
        content: '';
        width: 100%;
        display: block;
        padding-bottom: 1.5rem;
      }
    }
    &_subtitle{}
    &__description {
        padding-top: 1.5rem;
        font-size: 14px;
    }
    &__info{
        &__box{
            padding: 1rem;
            color: #5e7f96;
            border-color: #cfebfe;
            background-color: #dff2fe;
            font-size: 14px;
            display: flex;
            gap: 1rem;
            align-items: center;
            &__circle{
                color: #56b0ee;
                font-size: 1.7em;
            }
        }
    }
    &__image_box {
        position: relative;
        // gap: 0 1rem;
        margin: 1.5rem 0;
        width: 100%;
        &__title {
            width: 100%;
            display: block;
            // margin: 1rem 0;
            text-decoration: none;
            font-weight: 400;
            font-size: 14px;
            .text {
            }
        }
        &__link {
            text-decoration: none;
        }
        &__images {
            // padding-top: 0.5rem;
            // padding-bottom: 0.5rem;
            padding-top: 1.5rem;
            .image {
                display: block;
                height: auto;
                width: 100%;
            }
        }
    }
    &__images{
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        max-width: 100%;
        &__content{
            height: 100%;  
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            &__title{
                font-weight: 700;
                font-size: 34px;
                line-height: 45px;
                text-transform: none;
                letter-spacing: -2.3px;
                color: #223972;
            }
            &__image
            {
                .image
                {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        @media (min-width: 768px) {
            flex-direction: row;
        }
    }
}
.wysiwyg{
    li{
       margin-left:2rem;
    }
    table {
    border-collapse: collapse;
    width: 100%;
    border: 0px;
    }
    li::marker {
        width: 100%;
    }
    th, td {
    text-align: left;
    padding: 8px;
    border:0px
    }
    th{
        border-bottom: 1px solid black;
    }
    tr:nth-child(even){background-color: #f2f2f2}

    img{
        padding-top:1.5rem;
        display: block;
        height: auto;
        max-width: 100%;
        // @media (min-width: 768px) {
        //     width: auto;
        // }
    }

}

.q10__content__title_section {
    padding-block: 0.5rem;
    &__block {
        margin-bottom: 2.5rem;
        &__title {
            // margin-bottom: 1rem;
            // font-size: 20px;
            font-size: 13px;
            color: #223972;
            // font-weight: 700;
            line-height: 21px;
            padding: 1rem 0;
            font-weight: 400;
            display: flex;
            flex-direction: row;
            cursor: pointer;
            
            .title_question{
                margin-left: 0.5rem;
            }
        }

        &__subtitle {
            margin-bottom: 1rem;
        }
        &__paragraph {
            line-height: 1.5;
            font-size: 14px;
            transition: max-height 0.15s ease-out;
            background: #d5d5d5;

        }

        .orizontal_line{
            border: 0px;
            cursor: pointer;
            border-top: 1px solid #efefef;
            @media (min-width: 870px){
                max-width: 860px;
            }
        }

        .popUpText{
            overflow: hidden;
        }
        
    }
    .circleAdjust{
        // margin-top: 0.3rem;
    }
    .circle{
        background-color: #10aedf;
        color: white;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 14px;
    }
    
    .show-para{
        // padding: 0.6rem 0;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease-out;
    }

    .show{
        // max-height: 500px;
        max-height: auto;
        padding: 0;
        margin: 0;
    }

}

.mt-1{
    margin-top:1rem;
}

.wysiwyg{
    li{
       margin-left:2rem;
    }
    table {
    border-collapse: collapse;
    width: 100%;
    border: 0px;
    }
    li::marker {
        width: 100%;
    }
    th, td {
    text-align: left;
    padding: 8px;
    border:0px
    }
    th{
        border-bottom: 1px solid black;
    }
    tr:nth-child(even){background-color: #f2f2f2}

    img{
        padding-top:1.5rem;
        display: block;
        height: auto;
        max-width: 100%;
        // @media (min-width: 768px) {
        //     width: auto;
        // }
    }

}