.q10__header {
    width: 100%;
    //margin-bottom: 0.5rem;
    background: #223972;
    box-sizing: border-box;
    @media (min-width: 990px){
        background: var(--header_top_color);
    }
    box-shadow: 0 0 13px #c1cdec;
    z-index: 100;
    position: relative;
    &__top {
        @media  (min-width: 768px) {
            width: 750px;
        }
        @media (min-width: 992px){
            width: 970px;
        }
        @media (min-width: 1200px){
            width: 1170px;
        }
        height: 106px;
        @media (min-width:990px){
            height: 62px;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        
        
        // padding: 1.1rem 0rem;
        
        // background: #223972;
        // @media (min-width: 990px){
        //     background: var(--header_top_color);
        // }
        &__group {
            position: relative;
        }
        &__lang {
            color: var(--text_color);
            padding-right: 0.5rem;
            padding-left: 0.5rem;
            display: none;
            gap: 0.5rem;
            @media (min-width: 990px) { 
                padding-right: 3rem;
                display: flex;
            }
            a {
                color: var(--text_color);
                //text-decoration: none;
                font-weight: 100;
                font-family: "Roboto Condensed", sans-serif;
            }

            &__name {
                color: #040505;
                font-family: "Ubuntu", sans-serif;
                font-weight: 500;
                font-size: 15px;
                display: none;
                position: absolute;
                left: 50%;
                bottom: -30px;
                transform: translateX(-50%);
            }

            &__icon {
                .icon {
                    width: 35px;
                    height: auto;
                    display: block;
                }
                &:hover ~ p {
                    display: block;
                }
            }
        }

        &__menu {
            color: var(--text_color);
            display: none;
            justify-content: center;
            align-items: center;
            padding: 2rem 0;
            gap: 1rem;
            color: #040505;
            text-decoration: none;
            
            @media (min-width: 990px) {
                padding-right: 15px;
                display: flex;
            }
            & > * {
                text-decoration: none;
                font-family: 'Montserrat';
                color: #ffffff;
                font-weight: 500;
                font-size: 15px;
                letter-spacing: 0.4px;
                text-transform: capitalize;
            }
        }
        &__box {
            margin-bottom: -7.5px;
            display: flex;
            padding-left: 15px;
            // max-width: 50%;
            width: 180px;
            @media (min-width: 768px) {
                height: 36.35px
            }
            &__picture{
                background: white;
                border-radius: 35px;
                width: 100%;
                border: 6px solid white;
            }
            
        }
        &__burger_menu {
            padding-right: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            .line {
                width: 30px;
                height: 5px;
                background-color: white;
                margin: 6px;
                &:last-of-type {
                    margin-right: 0;
                }
            }
            @media (min-width: 990px) {
                display: none;
            }
        }
        // @media (min-width: 768px) {
        //     padding: 2rem 18%;
        //                 // box-shadow: unset;
        // }
    }
    @media (min-width: 768px) {
        background: var(--header_top_color);
    }

    &__popup {
        overflow: hidden;
        display: none;
        left: 0;
        opacity: 1;
        right: 0;
        transition: opacity 0.25s ease-out;
        position: fixed;
        bottom: 0;
        z-index: 999;
        top: 0;

        &__menu {
            background: #2a4891;
            & > * {
                color: white;
                text-decoration: none;
                display: flex;
                flex-wrap: wrap;
                line-height: 1;
                justify-content: flex-start;
                margin: 0;
                padding: 1rem;
                letter-spacing: -0.0375em;
                font-size: 15px;
                border-bottom: 1px solid rgba(241,241,241,0.22);
            }
        }

    }
}
.q10_header_logout {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background: black;
    padding: 1rem;
    .button {
        text-decoration: none;
    }
}
.active {
    display: block !important;
    left: 0;
    opacity: 1;
    right: 0;
    transition: opacity 0.25s ease-out;
    position: relative;
    z-index: 9999;
    background-color: #0093FF;
}

.active-lang {
    border-color: #dedfdf;
    border-style: solid;
    border-width: 0.1rem 0 0 0;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
}

.breadcrumbs{
    background: rgb(243, 245, 247);
    // overflow: hidden;
    position: relative;
    padding: 15px 15px 20px 15px;
    margin-bottom: 50px;
    &_slugs{
       
        margin: 0 auto;
        margin-top: 5px;
        
        @media (min-width: 768px) {
            text-align: left;
            padding-left: 15px;
            letter-spacing: 0.4px;
            // padding: 0rem 18%;
            // float: left;
            // clear: both;
                   
        }
        &_item{
            text-transform: capitalize;
            font-size: 16px;
            color: #333333;
        }
        @media  (min-width: 768px) {
            width: 750px;
        }
        @media (min-width: 992px){
            width: 970px;
        }
        @media (min-width: 1200px){
            width: 1170px;
        }
        .last{
            color: #6f95f7;
        }
    }
    * > li {
    display: inline-block;
    }
    * > li + li:before {
    color: #10aedf;
    content: ">";
    font-size: 16px;

    }
    * > .active {
    color: #777;
    }

}
