.q10__content__slider {
    margin: 0 auto;
    position: relative;
    width: 100%;
    padding: 0px;
    @media (min-width: 768px) {
        padding: 0 80px;
        width: 80%;
        margin-bottom: 6.3rem;
    }
    .arrow {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 50%;
        left: auto;
        right: auto;
        transform: translate(0, -50%);
        z-index: 100;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;

        &.arrow-left {
            left: 5px;
            @media (min-width: 768px) {
                left: 25px;
            }
        }

        &.arrow-right {
            right: 5px;
            @media (min-width: 768px) {
                right: 25px;
            }
        }
    }

    &__title {
        text-align: center;
        padding: 2rem;
        font-size: 40px;
        @media (min-width: 768px) {
            margin-bottom: 5rem;
        }
    }
    &__carousel {
        width: 100%;
        overflow: hidden;
        &__box {
            &__images {
                flex-direction: row;
                display: flex;
                justify-content: center;
                align-items: center;
                .image {
                    width: 100%;
                    height: auto;
                }
            }
            &__title {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 40px;
                text-align: center;

                @media (min-width: 768px) {
                    font-size: 55px;
                    font-weight: 500;
                    margin-bottom: 1rem;
                }
            }
            &__description {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 50px;
                font-size: 20px;
                text-align: justify;
            }
        }
    }
}
