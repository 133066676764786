:root {
    // ! Colors
    --header_top_color: linear-gradient(to right, #e0f5d8, #45c1e5);
    --header_nav_color: #ffffff;
    --primary_color:  #f8f9fa;;
    --secondary_color: #dce4f0;
    --text_color: #064266;
    --btn_color: #f37a31;
    --btn_color_hover: #c28400;
    --goToBtn_color: #dce4f0;
    --goToBtnArrow_color: #064266;
    --cookies_color: #162015;
    --cookies_box_shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

    // ! Spacing
    --header_height: 243px;
    --footer_height: 235px;
}

.dark-theme {
    --header_top_color: #7495c3;
    --header_nav_color: #064266;
    --primary_color: #0f192a;
    --secondary_color: #064266;
    --text_color: #ffffff;
    --goToBtn_color: #064266;
    --goToBtnArrow_color: #dce4f0;
    --cookies_color: rgba(15, 25, 42, 0.9);
    --cookies_box_shadow: rgba(89, 193, 238, 0.12) 0px 2px 4px 0px,
        rgba(89, 193, 238, 0.12) 0px 2px 16px 0px;
}
