.post_category{
    padding: 0;
    @media (min-width: 990px) {
        max-width: 100%;
        display: flex;
        flex-direction: column;   
    }
    &_blocks{
        width: 100%;
        @media (min-width: 990px) {
            width: 80%;
        }
        ul {
            list-style-type: none;
        }
    }
    &_blocks100{
        width: 100%;
        @media (min-width: 990px) {
            width: 100%;
        } 

        ul {
            list-style-type: revert;
        }
    }
    .title{
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    .child_title{
        font-size: 20px;
        font-weight: 500;
        color:#000;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }
    .content{
        margin-left:2rem;
        &_box{
            margin-bottom: 2rem;
            &_child{
                margin-left:2rem;
            }
        }
    }
    &_list{
        width: 100%;
        &_title{
            margin-bottom: 1rem;
            font-size: 20px;
            font-weight: bold;
        }
        &_box{
            overflow: unset;
            width: 100%;
            &_categories{
                width: 100%;
                display: flex; 
                flex-direction: column;
                &_item{
                    background: #f2f2f2;
                    padding: 1rem;
                    a {
                        text-decoration: none;
                        color: #223972;
                    }
                }
            }
            // @media (min-width: 768px) {
            //     overflow: hidden;
            //     width: 250px;
            // }
            @media (min-width: 990px) and (max-width: 1200px) {
                overflow: hidden;
                width: 200px;
            }
        }
        @media (min-width: 990px) {
            width: 20%;
            margin-left: 15px;
            position: sticky;
            height: 100%;
            top: 0;
        }
    }

    @media (min-width: 768px) {
        flex-direction: row;    
        gap:2rem;
    }

    ul li{
        border-bottom: 1px solid #efefef;
        padding: 1rem 0;
    }
    ul ul li{
        margin:0;
    }
    .post_category_child{
        margin-top: 2rem;
    }
}

.selected{
    border-left: 5px solid #10aedf;
}
