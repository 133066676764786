// @import "~bootstrap/scss/bootstrap";

@import "fonts";
@import "variables";
@import "header";
@import "footer";
@import "block";
@import "error_404";
@import "carousel";
@import "boxes";
@import "post_category";
@import "post";
@import "scroll_top";
@import "title_subtitle_description_section";
@import "_heading";
@import "_gallery";

*,
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition-property: background-color, color;
    transition-duration: 0.3s;
}

html {
    scroll-behavior: smooth;
    height: 100vh;
}

#app {
    background-color: var(--primary_color);
    min-height: 100vh;
    position: relative;
    font-family: "Poppins";
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.3px;
}
.background-app{
    background-color: white!important;

}

.single_block{
    & > :first-child {
        min-height: calc(100vh - var(--footer_height) - var(--header_height));
    }
}

.q10__content {
    position: relative;
    margin: auto;
    padding: 0 15px;
    // & > :first-child {
    //     min-height: calc(100vh - var(--footer_height) - var(--header_height));
    // }
    @media  (min-width: 768px) {
        width: 750px;
    }
    @media (min-width: 992px){
        width: 970px;
    }
    @media (min-width: 1200px){
        width: 1170px;
    }
}
a:hover{
    color:#6f95f7
}
a{
    text-decoration: none;
}
