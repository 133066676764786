.content_titled{
    &_box{
        margin-bottom: 2rem;
        margin-top: 2rem;
        &_title{
            padding: 1rem 0;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            text-transform: none;
            letter-spacing: -0.4px;
            color: #223972;
        }
        &_subtitle{
            padding: 1rem 0;
            margin-bottom: 0.5rem;
        }
    }
}