.heading{

    h1{
        font-size: 35px;
        color:#223972;
    }
    .subtitle{
        font-size: 15px;
        color: #596a94;
        margin-bottom: 20px;
        
    }

    hr{
        border: 1px solid #E4E4E4;
        margin-bottom: 20px;
    }
    .description{
        font-size:15px;
        margin-bottom: 20px;

    }
}