.content_error {

    text-align: center;
    margin: 120px 1px 120px 1px;
    &_title{
        font-size: 180px;
        line-height: 170px;
        letter-spacing: 0.3px;
        color: #002e5b;
        margin-bottom: 1rem;
    }
    &_subtitle{
        font-weight: 700;
        font-size: 28px;
        line-height: 35px;
        text-transform: none;
        letter-spacing: -1px;
        color: #223972;
        margin-bottom: 1rem;
    }
    &_description{
        font-size: 17px;
        margin-bottom: 53px;
        line-height: 1.3;
        color: #002e5b;
    }
}
